import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  FeedItem,
  isMemoFeedItem,
  isPostFeedItem,
  useGetGlobalFeed,
  GlobalFeedPeriodType,
  GlobalFeedSortType,
  GetGlobalFeedParams,
  isTradeFeedItem,
  FeedReason
} from '@commonstock/common/src/api/feed'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import { ScrollSentinel } from '../../utils/useScrollSentinel'
import AllCaughtUp from './AllCaughtUp'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { CardLayoutTypes, GlobalFeedPeriodKey, GlobalFeedSortKey } from './constants'
import { motion } from 'framer-motion'
import FeedSkeleton from './Feed.skeleton'
import { ScrollerClass } from '../nav/StandardScreen'
import { CSText } from '../../composing/CSText'
import MemoCard from '../memo/MemoCard'
import { useUser } from '../auth/AuthContext'
import { Routes } from '../nav/constants'
import FeedCreatePost from './FeedCreatePost'
import PostCard from '../posts/PostCard'
import { useRouter } from 'next/router'
import { DateTime } from 'luxon'
import { RequestStage } from '@commonstock/client/src/constants'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'
import GlobalFeedFilters from 'src/scopes/feed/GlobalFeedFilters'
import PaneHeader from 'src/scopes/nav/PaneHeader'
import { isLoggedIn as isLoggedInMethod } from '@commonstock/common/src/auth'
import { PaneColumn, PanelGrid } from 'src/components/styles'
import HomeDashboard from '../dashboard/HomeDashboard'
import { ZIndexLayers } from 'src/theme/constants'
import { useClient } from '@commonstock/client/src/react/context'
import useForceRender from 'src/utils/forceRender'
import TradeCard from '../trade/TradeCard'
import InAppMessageList from './InAppMessageList'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'

const animateTop = { y: '0', opacity: 1 }
const initialTop = { y: '-100%', opacity: 0 }
const duration = { duration: 0.7 }

function GlobalFeed() {
  const isLoggedIn = useMemo(() => isLoggedInMethod(), [])
  const [user] = useUser()

  const [period, setPeriod, periodPending] = usePersistedState<GlobalFeedPeriodType>(
    GlobalFeedPeriodKey,
    GlobalFeedPeriodType.RECENT
  )
  const [sortBy, setSortBy, sortPending] = usePersistedState<GlobalFeedSortType>(
    GlobalFeedSortKey,
    GlobalFeedSortType.TOP
  )

  // Is this still hapening? Can we remove this?
  // useEffect(() => {
  //   if (!Object.values(GlobalFeedSortType).includes(sortBy)) setSortBy(GlobalFeedSortType.TOP)
  // }, [setSortBy, sortBy])

  const feedParams: GetGlobalFeedParams = { query: { sort: sortBy } }
  if (sortBy === GlobalFeedSortType.TOP) feedParams.query.period = period
  const [feedPages, , , feedPager, feedRequests, firstPageLoaded] = useGetGlobalFeed(feedParams, {
    paused: periodPending || sortPending
  })

  const isPending = feedRequests[0]?.stage !== RequestStage.Success
  const feedItems: FeedItem[] | undefined = feedPages?.flatMap(a => a.items)
  const firstItem = feedItems?.[0]
  const isPostedRecently = hasPostedRecently(firstItem, user?.user_uuid)

  const router = useRouter()
  const [loaded, setLoaded] = useState(false)
  const feedRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!feedRef.current) return
    if (firstPageLoaded) return setLoaded(true)

    // Scroll to top, when clicking feed icon or refreshing page
    if (!firstPageLoaded && router.asPath === Routes.Home) {
      feedRef.current.closest(`.${ScrollerClass}`)?.scrollTo({ top: 0 })
      setLoaded(false)
    }
  }, [firstPageLoaded, router])

  const loadMore = useCallback(() => !feedPager.terminal && feedPager.next(), [feedPager])

  // TODO: Investigate if this is still resulting in 2 requests
  const refetch = useRefetchByKeys('get-global-feed')
  // Background update feed, everytime we change tabs
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  const prependItem = usePrependPost(
    // Using hard coded key ad feedRequests be filtering by Top instead of New
    'memory::get-global-feed:GET:{"query":{"sort":"New"}}0:',
    () => sortBy === GlobalFeedSortType.TOP && setSortBy(GlobalFeedSortType.NEW)
  )

  return (
    <PanelGrid>
      <PaneColumn ref={feedRef}>
        <PaneHeader title="Home" />
        <ScrollSentinel loadMore={loadMore}>
          {isLoggedIn && <FeedCreatePost prependItem={prependItem} redirect={user?.private} />}
          <GlobalFeedFilters
            period={period}
            setPeriod={period => setPeriod(period)}
            sortBy={sortBy}
            setSortBy={sortBy => setSortBy(sortBy)}
            // Used only to close the filter dropdown
            pending={isPending}
          />
          <InAppMessageList />
          <SkeletonWrapper
            pending={!loaded}
            skeleton={<FeedSkeleton />}
            failed={
              feedItems?.length === 0 && (
                <Spacing padding={[1, 0]}>
                  <CSText block centered>
                    No posts to display
                  </CSText>
                </Spacing>
              )
            }
          >
            <motion.div layout="position">
              {firstItem && isPostedRecently && (
                <AppendedNewPost item={firstItem} cardLayout={CardLayoutTypes.Upvote} />
              )}
              <motion.div transition={duration} layout={isPostedRecently ? 'position' : false}>
                {feedItems?.map((item, i) => {
                  if (i === 0 && isPostedRecently) return null
                  return <ContentTypeCard key={`${item.uuid}`} item={item} cardLayout={CardLayoutTypes.Upvote} />
                })}
              </motion.div>
            </motion.div>
            {!feedPager.terminal && <BrandSpinner />}
            {feedPager.terminal && <AllCaughtUp />}
          </SkeletonWrapper>
        </ScrollSentinel>
      </PaneColumn>
      {!isLoggedIn && (
        <PaneColumn>
          <HomeDashboard />
        </PaneColumn>
      )}
    </PanelGrid>
  )
}

export function hasPostedRecently(item: FeedItem | undefined, userUuid?: string) {
  if (!item || !userUuid || userUuid !== item.user_uuid) return false
  const now = DateTime.local()
  const past = DateTime.fromISO(item.created_at)
  const diff = now.diff(past, 'seconds')
  return diff.seconds < 5
}

export function usePrependPost(feedKey: string, callback?: () => void) {
  // const feedKey = feedRequests[0]?.cacheKey
  // Using hard coded key ad feedRequests be filtering by Top instead of New
  // const feedKey = 'memory::get-global-feed:GET:{"query":{"sort":"New"}}0:'
  let client = useClient()
  const forceRender = useForceRender()
  const prependItem = useCallback(
    async (feedItem: FeedItem) => {
      feedItem.reason_code = FeedReason.You
      feedItem.reason_text = 'Your Post'
      const firstPage = feedKey && client.cache.get(feedKey)

      if (firstPage) {
        firstPage.success?.payload.items.unshift(feedItem)
        client.cache.set(feedKey, firstPage)
        forceRender()
      }
      callback && callback()
    },
    [callback, client.cache, feedKey, forceRender]
  )

  return prependItem
}

export function AppendedNewPost({ item, cardLayout }: { item: FeedItem; cardLayout?: CardLayoutTypes }) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const onLayoutAnimationComplete = () => {
    requestAnimationFrame(() => {
      if (!wrapperRef.current) return
      wrapperRef.current.style.removeProperty('transform')
    })
  }

  return (
    <motion.div
      ref={wrapperRef}
      initial={initialTop}
      animate={animateTop}
      transition={duration}
      key={item.uuid}
      style={{ zIndex: ZIndexLayers.SlightlyUp, position: 'relative' }}
      onAnimationComplete={onLayoutAnimationComplete}
    >
      <ContentTypeCard shouldSlide item={item} cardLayout={cardLayout} />
    </motion.div>
  )
}

type ContentTypeProps = {
  item: FeedItem
  cardLayout?: CardLayoutTypes
  shouldSlide?: boolean
  allowTrade?: boolean
}
export function ContentTypeCard({ item, shouldSlide, cardLayout, allowTrade }: ContentTypeProps) {
  if (allowTrade && item && isTradeFeedItem(item)) return <TradeCard key={item.uuid} tradeFeedItem={item} />
  if (item && isMemoFeedItem(item)) return <MemoCard key={item.uuid} memoFeedItem={item} cardLayout={cardLayout} />
  if (item && isPostFeedItem(item))
    return <PostCard key={item.uuid} postFeedItem={item} forceShow={shouldSlide} cardLayout={cardLayout} />
  else return null
}

export default GlobalFeed
